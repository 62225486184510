define("huvepharma-aviapp-admin/validations/models/add", ["exports", "ember-changeset-validations/validators", "huvepharma-aviapp-admin/validations/change-description"], function (_exports, _validators, _changeDescription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    company: {
      poultry: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_company_poultry'),
      name: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_companyname'),
      street: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_street'),
      number: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'requestaccess_companynumber'),
      city: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_company_city'),
      zipcode: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'requestaccess_companyzipcode'),
      countryCode: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'requestaccess_companycountry'),
      region: (0, _changeDescription.default)([], 'clients_add_companyregion'),
      email: (0, _changeDescription.default)([(0, _validators.validateFormat)({
        allowBlank: true,
        type: 'email'
      })], 'label_company_email'),
      telephone: (0, _changeDescription.default)([], 'requestaccess_companyphone')
    },
    owner: {
      firstName: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_firstname'),
      name: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_last_name'),
      street: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_street'),
      number: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_number'),
      city: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_city'),
      zipcode: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_zipcode'),
      region: (0, _changeDescription.default)([], 'label_region'),
      countryCode: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_country'),
      username: (0, _changeDescription.default)([(0, _validators.validateFormat)({
        allowBlank: false,
        type: 'email'
      }), (0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true,
        on: 'owner.sameAddress'
      })], 'label_email'),
      telephone: (0, _changeDescription.default)([(0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true
      })], 'label_phone')
    },
    acceptedUserLicenceAgreement: (0, _changeDescription.default)([(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'errors_accepted'
    })], 'userlicenseagreement_termsandconditions'),
    acceptedPrivacyStatement: (0, _changeDescription.default)([(0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      message: 'errors_accepted'
    })], 'userlicenseagreement_termsandconditions')
  };
  _exports.default = _default;
});